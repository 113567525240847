<template>
  <div class="row card-container">
    <div class="col">
      <form class="form card card-body responsive">
        <h5 class="font-20">メールの送信が完了しました</h5>
        <div class="form-group mx-auto">
          <p class="text-left">パスワードリセットに必要なメールを送信しました。
            メール記載のリンクからパスワードの再設定に進んでください。
          </p>
        </div>
        <button class="btn btn-primary font-weight-bold mx-auto mt-4" type="submit" @click="login">ログインに戻る</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordEmailSent",
  methods: {
    login(e) {
      e.preventDefault()
      this.$router.push({
        name: 'Login'
      });
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}
</script>

<style scoped>

</style>